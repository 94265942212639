import { auth } from './firebase';  // Import Firebase auth instance
import { onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import ReactDOM from 'react-dom';

// const API_URL = 'http://127.0.0.1:5000/';
const API_URL = process.env.REACT_APP_API_URL || "https://whats-it-worth-server-38dfe6b0d49b.herokuapp.com"

export async function analyzeItem(brand, photo, price, currency) {
    try {
        const formData = new FormData();
        if (brand) {
            formData.append('brand', brand);
        }
        if (photo) {
            formData.append('photo', photo);
        }

        if (price) {
            formData.append('price', price);
        }
        if (currency) {
            formData.append('currency', currency);
        }
        const response = await fetch(`${API_URL}/analyze`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.error('Error analyzing item:', error);
        return 'Error analyzing item.';
    }
}

export const createCheckoutSession = async (serviceType) => {
    const token = await getIdToken();
    try {
        const response = await fetch(`${API_URL}/api/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ serviceType })
        });

        if (!response.ok) {
            throw new Error('Failed to create checkout session');
        }

        const session = await response.json();
        return session;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const checkPaymentStatus = async (idToken) => {

    try {
        const response = await fetch(`${API_URL}/api/check-payment-status`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id_token: idToken })
        });

        if (!response.ok) {
            throw new Error('Failed to check payment status');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const authCallback = async (idToken) => {
    try {
        const response = await fetch(`${API_URL}/api/auth-callback`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id_token: idToken })
        });
        if (!response.ok) {
            throw new Error('Failed to authenticate with backend');
        }
        return response;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const fetchUserProfile = async () => {
    try {
        const idToken = await getIdToken();
        const response = await fetch(`${API_URL}/api/user-profile`, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (err) {
        throw new Error(`Failed to fetch user profile: ${err.message}`);
    } finally {
    }
};

export const handleUpdateSubscription = async (newPlan) => {
    try {
        const idToken = await getIdToken();

        // If user chose lifetime plan, confirm the one-time purchase
        if (newPlan === 'lifetime') {
            const confirmLifetime = window.confirm(
                "The lifetime plan is a one-time purchase of $99 and cannot be changed later. Are you sure you want to upgrade to the lifetime plan?"
            );
            if (!confirmLifetime) {
                return null;
            }
        }

        const response = await fetch(`${API_URL}/api/update-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({ new_plan: newPlan })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.checkout_url) {
            // Return the checkout URL for redirection
            return result;
        } else {
            // This case is now unlikely, but we'll keep it for robustness
            alert(`Your subscription has been successfully updated to the ${newPlan} plan.`);
            return result;
        }
    } catch (err) {
        console.error('Error in handleUpdateSubscription:', err);
        throw new Error(`Failed to update subscription: ${err.message}`);
    }
};

export const handleCancelSubscription = async () => {
    // First pop-up: Option to change plan instead of canceling
    const changeOrCancel = window.confirm(
        "Instead of canceling, would you like to change your subscription plan?\n\nClick 'OK' to change plan, or 'Cancel' to proceed with cancellation."
    );

    if (changeOrCancel) {
        // User chose to change plan
        const newPlan = window.prompt(
            "Which plan would you like to switch to? Enter 'weekly' or 'monthly':"
        );
        if (newPlan && (newPlan.toLowerCase() === 'weekly' || newPlan.toLowerCase() === 'monthly')) {
            return handleUpdateSubscription(newPlan.toLowerCase());
        } else {
            alert("Invalid plan selection. No changes were made to your subscription.");
            return;
        }
    }

    // Show feedback form modal
    await showFeedbackForm();

    // Second pop-up: Confirm cancellation
    const confirmCancel = window.confirm(
        "Are you sure you want to cancel your subscription? You'll be redirected to the Stripe Customer Portal."
    );

    if (!confirmCancel) {
        return; // User decided not to proceed
    }

    try {
        const idToken = await getIdToken();
        const response = await fetch(`${API_URL}/api/cancel-subscription`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.url) {
            // Redirect the user to the Stripe Customer Portal
            window.location.href = result.url;
        } else {
            throw new Error('Failed to get Stripe Customer Portal URL');
        }
    } catch (err) {
        console.error('Error in handleCancelSubscription:', err);
        alert(`Failed to access subscription management: ${err.message}`);
    }
};

const showFeedbackForm = () => {
    return new Promise((resolve) => {
        const FeedbackFormComponent = React.lazy(() => import('./components/Forms/Feedback'));
        const feedbackRoot = document.createElement('div');
        document.body.appendChild(feedbackRoot);

        ReactDOM.render(
            <React.Suspense fallback={<div>Loading...</div>}>
                <FeedbackFormComponent
                    open
                    setOpen={(isOpen) => {
                        if (!isOpen) {
                            ReactDOM.unmountComponentAtNode(feedbackRoot);
                            document.body.removeChild(feedbackRoot);
                            resolve();
                        }
                    }}
                    isCanceling
                />
            </React.Suspense>,
            feedbackRoot
        );
    });
};

export const handleDeleteAccount = async () => {

    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
        try {
            const idToken = await getIdToken();
            const response = await fetch(`${API_URL}/api/delete-account`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
            }
            await auth.signOut();
        } catch (err) {
            throw new Error(`Failed to delete account: ${err.message}`);
        }
    }
};

async function getIdToken() {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const token = await user.getIdToken(true); // Force refresh to get a fresh token
                    resolve(token);
                } catch (error) {
                    reject(error);
                }
            } else {
                reject(new Error('User not authenticated'));
            }
        });
    });
}